
    
    <template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-if="!loading" my-2>
            <form @submit.prevent="update_admins" autocomplete="off">
                <v-layout row wrap>
                    
        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="text" v-model="admins.admin_name" :label="$store.getters.lang.data.admin_name"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        


        <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field rounded type="text" v-model="admins.admin_password" :label="$store.getters.lang.data.admin_password"
                            outlined class="mx-1" filled  required>
                        </v-text-field>
                    </v-flex>
        

    
    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn color="primary" rounded class="mx-1"  type="submit" x-large>{{$store.getters.lang.data.update_btn}}</v-btn>
                    </v-flex>    
                </v-layout>
            </form>
        </v-container>
        
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>

<script>
    import controller from './../../controller/admins'
    export default {
        data() {
            return {
                admins: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },

            }
        },
        computed: {
            
            
        },
        methods: {
            get_admins() {
                this.loading = true
                controller.getOne(this.id).then(r =>{
                    this.admins = r.data.row
                    this.loading = false
                }).catch(e =>{
                    console.log(e);
                })
            },
            update_admins() {
                controller.update_admins(this.admins).then(r => {
                    if (r.data) {
                        this.snackbar = {
                            value: true,
                            text: 'Updated',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Not Updated',
                            color: 'error'
                        }
                    }
                })
            },
            
        },
        mounted() {
            this.id = this.$route.params.id
            this.get_admins()
        },
    }
</script>
    
    